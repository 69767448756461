import { lazy } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import MainApp from '../layout/main-app'
import ErrorPage from '../pages/error/error'
import AuthBackGround from '../layout/auth/helper/authBackGround'
import { useAuth } from '../context/AuthContext'

const router = (currentUser: string | null) => {
	const Forget = lazy(() => import('../layout/auth/forget'))
	const Login = lazy(() => import('../layout/auth/login'))
	const OTPPage = lazy(() => import('../layout/auth/otpPage'))
	const Schedules = lazy(() => import('../pages/Schedules/Schedules'))
	const Location = lazy(() => import('../pages/Location/Location'))
	const Permissions = lazy(
		() => import('../pages/UserManagements/Permissions/Permissions')
	)
	const TeamMemberlist = lazy(
		() => import('../pages/UserManagements/ TeamMember/ TeamMemberlist')
	)
	const AddTeamMember = lazy(
		() => import('../pages/UserManagements/ TeamMember/AddTeamMember')
	)
	const ChangePassword = lazy(() => import('../layout/auth/changePassword'))
	const AssignRolePermissions = lazy(
		() => import('../pages/UserManagements/Permissions/assignRolePermissions')
	)
	const Invoice = lazy(() => import('../pages/Finance/Invoice/Invoice'))
	const InvoiceForm = lazy(() => import('../pages/Finance/Invoice/InvoiceForm'))
	const InvoicePreview = lazy(
		() => import('../pages/Finance/Invoice/InvoicePreview')
	)
	const Payroll = lazy(() => import('../pages/Finance/Payroll/Payroll'))
	const PayrollDetails = lazy(
		() => import('../pages/Finance/Payroll/PayrollPreview')
	)
	const PayrollForm = lazy(() => import('../pages/Finance/Payroll/PayrollForm'))
	const TimeOffCategory = lazy(
		() => import('../pages/Timeoff/category/TimeOffCategory')
	)
	const Service = lazy(() => import('../pages/Service/Service'))
	const Audit = lazy(() => import('../pages/Audit/Audit'))
	const UnderDevelopment = lazy(() => import('../pages/error/underDevelopment'))
	const Profile = lazy(() => import('../pages/Profile/Profile'))
	const ReportSchedule = lazy(
		() => import('../pages/Reports/Payroll/PayrollReport')
	)
	const TimeSheetList = lazy(
		() => import('../pages/Reports/TimeSheet/TimeSheetList')
	)
	const Settings = lazy(() => import('../pages/Settings/Settings'))
	const TimeOffRequest = lazy(
		() => import('../pages/Timeoff/request/timeOffRequest')
	)
	const Dashboard = lazy(() => import('../pages/dashboard/dashboard'))
	const InvoiceSchedule = lazy(() => import('../pages/Reports/Report'))
	const AcceptInvite = lazy(() => import('../layout/auth/AcceptInvite'))
	const Chat = lazy(() => import('../pages/Communications/Chat/Chat'))
	const Expense = lazy(() => import('../pages/Finance/Expense/Expense'))
	const ExpenseForm = lazy(() => import('../pages/Finance/Expense/ExpenseForm'))

	return createBrowserRouter([
		{
			path: '/',
			element: currentUser ? <MainApp /> : <Navigate to='/auth' />,
			errorElement: <ErrorPage />,
			children: [
				{ index: true, element: <Navigate to='/schedules' replace /> },
				{
					path: 'dashboard',
					element: <Dashboard />,
				},
				{
					path: 'users',
					children: [
						{
							path: '',
							element: <TeamMemberlist />,
						},
						{
							path: 'edit/:id',
							element: <AddTeamMember />,
						},
						{
							path: 'create',
							element: <AddTeamMember />,
						},
						{
							path: 'roles',
							children: [
								{
									path: '',
									element: <Permissions />,
								},
								{
									path: 'permissions/:role_id',
									element: <AssignRolePermissions />,
								},
							],
						},
					],
				},
				{
					path: 'locations',
					element: <Location />,
				},
				{
					path: 'services',
					element: <Service />,
				},
				{
					path: 'schedules',
					element: <Schedules />,
				},
				{
					path: 'timeoff',
					children: [
						{
							path: '',
							element: <TimeOffRequest />,
						},
						{
							path: 'timeoff-categories',
							element: <TimeOffCategory />,
						},
						{
							path: 'timeoff-requests',
							element: <TimeOffRequest />,
						},
					],
				},
				{
					path: 'finances',
					children: [
						{
							path: '',
							element: <Invoice />,
						},
						{
							path: 'invoices',
							children: [
								{
									path: 'create',
									element: <InvoiceForm />,
								},
								{
									path: 'edit/:id',
									element: <InvoiceForm />,
								},
								{
									path: 'preview/:id',
									element: <InvoicePreview />,
								},
								{
									path: '',
									element: <Invoice />,
								},
							],
						},
						{
							path: 'expenses',
							children: [
								{
									path: 'create',
									element: <ExpenseForm />,
								},
								{
									path: 'edit/:id',
									element: <ExpenseForm />,
								},
								{
									path: '',
									element: <Expense />,
								},
							],
						},
						{
							path: 'payrolls',
							children: [
								{
									path: 'create',
									element: <PayrollForm />,
								},
								{
									path: 'preview/:id',
									element: <PayrollDetails />,
								},
								{
									path: '',
									element: <Payroll />,
								},
							],
						},
					],
				},
				// {
				// 	path: 'Report',
				// 	children: [
				// 		{
				// 			path: 'report-schedule',
				// 			element: <ReportSchedule />,
				// 		},
				// 	],
				// },
				{
					path: 'settings',
					element: <Settings />,
				},

				{
					path: 'communications',
					// element: <UnderDevelopment />,
					children: [
						{
							path: 'chat',
							element: <Chat />,
						},
						{
							path: 'FAQ’s',
							element: <UnderDevelopment />,
						},
						{
							path: 'Announcement',
							element: <UnderDevelopment />,
						},
						{
							path: '',
							element: <Chat />,
						},
					],
				},
				{
					path: 'audit-logs',
					element: <Audit />,
				},

				{
					path: 'report',
					children: [
						{
							path: '',
							element: <TimeSheetList />,
						},
						{
							path: 'invoice',
							element: <InvoiceSchedule />,
						},
						{
							path: 'report',
							element: <ReportSchedule />,
						},
						{
							path: 'timesheets',
							element: <TimeSheetList />,
						},
					],
				},
				{
					path: 'profile',
					element: <Profile />,
				},
			],
		},
		{
			path: '/auth',
			element: currentUser ? <Navigate to='/' /> : <AuthBackGround />,
			errorElement: <ErrorPage />,
			children: [
				{
					path: 'forget-password',
					element: <Forget />,
				},
				{
					path: 'reset-password/:credentials',
					element: <ChangePassword />,
				},
				{
					path: 'accept/:invite_credentials',
					element: <AcceptInvite />,
				},
				{
					path: 'otp/:email',
					element: <OTPPage />,
				},
				{
					path: 'login',
					element: <Login />,
				},
				{
					path: '',
					element: <Login />,
				},
			],
		},
	])
}
export default function Router() {
	const { currentUser } = useAuth()
	return <RouterProvider router={router(currentUser)} />
}
