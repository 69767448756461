import {
	faArrowLeft,
	faArrowRightFromBracket,
	faBars,
	faBell,
	faCalendarDays,
	faCalendarXmark,
	faClipboard,
	faFontAwesome,
	faGear,
	faLocationDot,
	faMoneyBill,
	faNotesMedical,
	faUserPen,
	faUsers,
	faVolumeUp,
} from '@fortawesome/free-solid-svg-icons'
import React, { Suspense, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import SidebarItem from './component/SidebarItem'
import { useAuth } from '../../context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fileSrc } from '../../component/FileLoader'
import { useQuery } from '@tanstack/react-query'
import { settings_api } from '../../service/settings'

export default function WebLayout() {
	const { setToken, currentUser, setCurrentUser } = useAuth()
	const [showNotification, setShowNotification] = useState(true)
	const [isMenuOpen, setMenuOpen] = useState(false)
	const { data: settings } = useQuery({
		queryKey: ['settings'],
		queryFn: settings_api,
	})
	const [showProfileDropDown, setShowProfileDropDown] = useState(true)
	const [showMobileSideBar, setShowMobileSideBar] = useState(false)

	let styleHearder: any = {
		fontWeight: '500',
		lineHeight: '24px',
		letterSpacing: '0.1px',
		fontFamily: 'Inter',
	}
	return (
		<div className='h-screen bg-[#F1F4FB]'>
			<div className='bg-[#F1F4FB] h-full'>
				<header
					className={`no-print sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-[48]
				 w-full bg-white border-b text-sm py-2.5 sm:py-4  transition-all duration-300 
				 ${isMenuOpen && 'lg:ps-24 '}
				 ${!isMenuOpen && 'lg:ps-64 '}`}
				>
					<nav
						className='flex basis-full items-center w-full mx-auto px-4 sm:px-6 md:px-8'
						aria-label='Global'
					>
						<div className='me-5 lg:me-0 lg:hidden'>
							<div className='flex-none text-xl font-semibold flex'>
								<button
									onClick={() => setShowMobileSideBar(!showMobileSideBar)}
									type='button'
									className='pr-2 text-gray-500 hover:text-gray-600'
								>
									<span className='sr-only'>Toggle Navigation</span>
									<svg
										className='flex-shrink-0 size-6'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									>
										<line x1='3' x2='21' y1='6' y2='6' />
										<line x1='3' x2='21' y1='12' y2='12' />
										<line x1='3' x2='21' y1='18' y2='18' />
									</svg>
								</button>
								<img
									className='sm:h-8 sm:w-8 h-7 w-7 object-contain'
									src={
										settings?.business_logo
											? fileSrc({
													id: settings?.business_logo,
													provider: 'cloudinary',
													format: '',
											  })
											: 'https://res.cloudinary.com/tobilasinde/image/upload/v1715547435/jd3x68ohm3szqdm0uodr.jpg'
									}
									alt=''
								/>
								{/* <p style={styleHearder} className='px-2 text-sm'>
									{settings?.business_name}
								</p> */}
							</div>
						</div>

						<div className='w-full flex items-center  justify-end ms-auto sm:justify-between sm:gap-x-3 sm:order-3'>
							{/* <div className='sm:hidden'>
								<button
									type='button'
									className='w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none '
								>
									<svg
										className='flex-shrink-0 size-4'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									>
										<circle cx='11' cy='11' r='8' />
										<path d='m21 21-4.3-4.3' />
									</svg>
								</button>
							</div> */}

							<div className='flex item-center '>
								<button
									className='mr-3 invisible lg:visible'
									onClick={() => setMenuOpen(!isMenuOpen)}
								>
									{!isMenuOpen && <FontAwesomeIcon icon={faBars} />}
									{isMenuOpen && <FontAwesomeIcon icon={faArrowLeft} />}
								</button>
								<p
									style={styleHearder}
									className='px-2 py-auto text-sm uppercase'
								>
									{settings?.business_name}
								</p>
							</div>
							{/* <div className='hidden sm:block'>
								<div>
									<label
										htmlFor='hs-trailing-button-add-on-with-icon'
										className='sr-only'
									>
										Search
									</label>
									<div className='flex itme-center '>
										<div className='flex rounded-lg shadow-sm '>
											<input
												type='text'
												id='hs-search-box-with-loading-4'
												name='hs-search-box-with-loading-4'
												className='py-3 px-4 block w-full border-none shadow-sm rounded-s-lg text-sm focus:z-10  0 disabled:opacity-50 disabled:pointer-events-none '
												placeholder=''
											/>
											<button
												type='button'
												className='py-3 px-4 inline-flex justify-center text-white items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-[#EC7211] text-white disabled:opacity-50 disabled:pointer-events-none '
											>
												<svg
													className='w-5 h-5 text-white'
													aria-hidden='true'
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 20 20'
												>
													<path
														stroke='currentColor'
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth='2'
														d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
													/>
												</svg>
												Search
											</button>
										</div>
									</div>
								</div>
							</div> */}

							<div className='flex flex-row items-center justify-end gap-2'>
								<div className='hs-dropdown relative inline-flex'>
									<button
										onClick={() => setShowNotification(!showNotification)}
										id='hs-dropdown-default'
										type='button'
										className='hs-dropdown-toggle p-2 lg:py-3 lg:px-4 inline-flex items-center gap-x-2 bg-gray-100
									text-sm font-medium rounded lg:rounded-lg  text-gray-800
									 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none '
									>
										<svg
											className='flex-shrink-0 size-4'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										>
											<path d='M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9' />
											<path d='M10.3 21a1.94 1.94 0 0 0 3.4 0' />
										</svg>
									</button>

									<div
										className={`
										hs-dropdown-menu transition-[opacity,margin] duration 
										${
											showNotification &&
											'z-40   hs-dropdown-open:opacity-100 opacity-0 hidden'
										} min-w-60 bg-white
									    shadow-md rounded-lg mt-10  absolute after:h-4 after:absolute after:-bottom-4 after:start-0
										 after:w-full w-[350px] before:h-4  before:absolute before:-top-4 before:start-0 before:w-full`}
										aria-labelledby='hs-dropdown-default'
									>
										<a
											className='flex items-center p-3 bg-[#EC7211] gap-x-3.5 p-4 font-bold rounded-t-lg  text-gray-800 hover:bg-gray-100 focus:outline-none 
										focus:bg-gray-100 text-[20px]'
											href='#!'
										>
											Notification
										</a>

										<a
											className='flex border items-center gap-x-3.5 py-2 px-3 rounded-lg  text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 '
											href='#!'
										>
											<span className='rounded-full p-4 size-10 flex justify-center items-center bg-[#FFF4EC]'>
												<FontAwesomeIcon icon={faBell} />
											</span>
											<div>
												<p className='text-[15px]'>
													A new schedule has been create ..
												</p>
												<p className='text-[#EC7211]'>2/1/2024</p>
											</div>
										</a>
									</div>
								</div>
								<div className='hs-dropdown [--placement:bottom-right]'>
									<button
										onClick={() => setShowProfileDropDown(!showProfileDropDown)}
										id='hs-dropdown-with-header'
										type='button'
									>
										<div className='relative inline-flex items-center'>
											<div className='w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none '>
												<img
													className='inline-block size-[38px] rounded-full ring-2 ring-white '
													src={
														currentUser.file
															? fileSrc(currentUser.file)
															: 'https://i.pinimg.com/originals/c0/27/be/c027bec07c2dc08b9df60921dfd539bd.webp'
													}
													alt='Imag Description'
												></img>
											</div>
											<div className='ms-3 hidden sm:block'>
												<h3
													style={{
														fontFamily: 'Inter',
														fontSize: '12px',
														fontStyle: 'normal',
														fontWeight: '400',
														lineHeight: '16px' /* 133.333% */,
														letterSpacing: '0.4px',
													}}
													className='font-semibold text-left text-gray-800 '
												>
													{currentUser?.firstname} {currentUser?.lastname}
												</h3>
												<p
													style={{
														fontFamily: 'Inter',
														fontSize: '10px',
														fontStyle: 'normal',
														fontWeight: 500,
														lineHeight: '16px' /* 160% */,
														letterSpacing: '1.5px',
														textTransform: 'uppercase',
													}}
													className='text-sm font-medium text-gray-400'
												>
													{currentUser?.email}
												</p>
											</div>
										</div>
									</button>
									<div
										className={` ${
											showProfileDropDown &&
											' hs-dropdown-menu  opacity-0 hidden  transition-[opacity,margin]'
										}
										transition-[opacity,margin] duration hs-dropdown-open:opacity-100
										min-w-60 bg-white shadow-md absolute rounded-lg p-2 `}
										aria-labelledby='hs-dropdown-with-header'
									>
										<div className=' py-2 first:pt-0 last:pb-0'>
											<Link
												to='/profile'
												className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
                                             hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 '
												onClick={() =>
													setShowProfileDropDown(!showProfileDropDown)
												}
											>
												<FontAwesomeIcon icon={faUserPen} />
												Profile
											</Link>
										</div>
										<div className='py-2 last:pb-0'>
											<button
												onClick={() => {
													setToken()
													setCurrentUser()
												}}
												className='flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
                                             hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 '
											>
												<FontAwesomeIcon icon={faArrowRightFromBracket} />
												log out
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</header>
				{/* <div className='sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden '>
					<div className='flex items-center py-4'>
						<button
							onClick={() => setShowMobileSideBar(!showMobileSideBar)}
							type='button'
							className='text-gray-500 hover:text-gray-600'
						>
							<span className='sr-only'>Toggle Navigation</span>
							<svg
								className='flex-shrink-0 size-4'
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							>
								<line x1='3' x2='21' y1='6' y2='6' />
								<line x1='3' x2='21' y1='12' y2='12' />
								<line x1='3' x2='21' y1='18' y2='18' />
							</svg>
						</button>
					</div>
				</div> */}

				{showMobileSideBar && (
					<button
						onClick={() => setShowMobileSideBar(!showMobileSideBar)}
						className='text-gray-400 hover:text-white absolute bg-black z-30 opacity-5 h-screen w-full top-0 m-1 right-0'
					>
						{/* &times; */}
					</button>
				)}

				<div
					id='application-sidebar'
					className={`no-print 
					 transition-all duration-300
					 transform  fixed lg:top-0 lg:start-0 lg:bottom-0 lg:z-[60] ${
							!isMenuOpen ? 'lg:w-64' : 'lg:w-28'
						} 
					 bg-white border-e border-gray-200 fixed z-40 
					 pt-7 pb-10 ${!isMenuOpen ? 'overflow-hidden ' : ''} 
					 block lg:end-auto lg:bottom-0 lg:translate-x-0 ${
							showMobileSideBar ? 'translate-x-0' : '-translate-x-full'
						}
					 `}
				>
					<div className='px-6'>
						<span
							className='flex-none text-xl flex font-semibold '
							aria-label='Brand'
						>
							<img
								className='h-7 object-contain m-auto'
								src={
									settings?.business_logo
										? fileSrc({
												id: settings?.business_logo,
												provider: 'cloudinary',
												format: '',
										  })
										: 'https://res.cloudinary.com/tobilasinde/image/upload/v1715547435/jd3x68ohm3szqdm0uodr.jpg'
								}
								alt=''
							/>
						</span>
					</div>

					<nav
						className='hs-accordion-group pt-5 pl-5 w-full  flex flex-col flex-wrap'
						data-hs-accordion-always-open
					>
						<ul className='space-y-1.5 '>
							{/* <SidebarItem
								isMenuOpen={isMenuOpen}
								link={'dashboard'}
								id={'dashboard'}
								title={'Dashboard'}
								icon={faHouse}
							/> */}

							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'schedules'}
								id={'schedules'}
								title={'Schedules'}
								icon={faCalendarDays}
								onClick={() => setShowMobileSideBar(false)}
							/>

							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'locations'}
								id={'locations'}
								title={'Locations'}
								icon={faLocationDot}
								onClick={() => setShowMobileSideBar(false)}
							/>

							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'services'}
								id={'services'}
								title={'Services'}
								icon={faNotesMedical}
								onClick={() => setShowMobileSideBar(false)}
							/>

							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'finances'}
								id={'finances'}
								title={'Finance'}
								icon={faMoneyBill}
								dropdownList={[
									{ link: 'invoices', title: 'Invoices', id: 'invoices' },
									{ link: 'payrolls', title: 'Payrolls', id: 'payrolls' },
									{ link: 'expenses', title: 'Expenses', id: 'expenses' },
								]}
								onClick={() => setShowMobileSideBar(false)}
							/>

							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'communications'}
								id={'communications'}
								title={'Communications'}
								icon={faVolumeUp}
							/>
							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'users'}
								id={'users'}
								title={'User Management'}
								icon={faUsers}
								dropdownList={[
									{
										link: '',
										title: 'Team Members',
										id: 'users',
									},
									{
										link: 'roles',
										title: 'Roles',
										id: 'roles',
									},
								]}
								onClick={() => setShowMobileSideBar(false)}
							/>
							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'timeoff'}
								id={'timeoff'}
								title={'Time off'}
								icon={faCalendarXmark}
								dropdownList={[
									{
										link: 'timeoff-requests',
										title: 'Time Off Requests',
										id: 'timeoff-requests',
									},
									{
										link: 'timeoff-categories',
										title: 'Time Off Category',
										id: 'timeoff-categories',
									},
								]}
								onClick={() => setShowMobileSideBar(false)}
							/>
							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'report'}
								id={'report'}
								title={'Report'}
								icon={faClipboard}
								dropdownList={[
									{
										link: 'timesheets',
										title: 'Timesheet',
										id: 'timesheets',
									},
									// {
									// 	link: 'report',
									// 	title: 'Schedule',
									// 	id: 'report-schedule',
									// },
									// {
									// 	link: 'invoice',
									// 	title: 'Invoice',
									// 	id: 'invoice-schedule',
									// },
								]}
								onClick={() => setShowMobileSideBar(false)}
							/>
							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'settings'}
								id={'settings'}
								title={'Settings'}
								icon={faGear}
								onClick={() => setShowMobileSideBar(false)}
							/>
							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'audit-logs'}
								id={'audit-logs'}
								title={'Audit'}
								icon={faFontAwesome}
								onClick={() => setShowMobileSideBar(false)}
							/>
							<SidebarItem
								isMenuOpen={isMenuOpen}
								link={'profile'}
								id={'profile'}
								title={'Profile'}
								icon={faUserPen}
								onClick={() => setShowMobileSideBar(false)}
							/>
						</ul>
					</nav>
				</div>
				<div
					id='c-close'
					className={`w-full h-full pt-10 px-4 transition-all duration-300  sm:px-6 md:px-8 
				${!isMenuOpen && 'lg:ps-72'} 
				${isMenuOpen && 'lg:ps-32'} 
				bg-[#F1F4FB]`}
				>
					<Suspense>
						<Outlet />
					</Suspense>
					<br />
					<br /> <br />
				</div>
			</div>
		</div>
	)
}
