import axios from 'axios'
import { Response, queryParam } from './_model'
import { RoleModel } from './role'
import { PermissionsModel } from './permissions'
import { FileModel } from './misc'
import { OnboardingSettingsModel } from './settings'

// api.js
const BASE_URL = process.env.REACT_APP_BASE_URL

export const users_api = async (
	data: queryParam
): Promise<Response<UserModel[]>> => {
	return await axios.get(`${BASE_URL}/user?${data.queryKey[1] || ''}`)
}
export const user_api = async (data: queryParam): Promise<UserModel> => {
	return await axios.get(`${BASE_URL}/user/${data.queryKey[1] || ''}`)
}

export const create_users_api = async (data: UserModel): Promise<UserModel> => {
	return await axios.post(`${BASE_URL}/user`, data)
}
export const accept_invitation = async (data: any): Promise<any> => {
	return await axios.post(`${BASE_URL}/user/accept_invite`, data.queryKey[1])
}

export const update_users_api = async (data: UserModel): Promise<UserModel> => {
	return await axios.put(`${BASE_URL}/user/${data.id}`, data)
}

export const delete_users_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/user/${data}`)
}

export const get_user_profile_api = async (): Promise<UserModel> => {
	return await axios.get(`${BASE_URL}/profile`)
}
export const get_user_profile_onboarding_api = async (
	data: queryParam
): Promise<Response<ProfileOnboarding[]>> => {
	return await axios.get(
		`${BASE_URL}/profile/onboarding?${data.queryKey[1] || ''}`
	)
}

export const update_profile = async (data: UserModel) => {
	return await axios.put(`${BASE_URL}/profile`, data)
}
export const create_profile_onboarding = async (
	data: ProfileOnboarding
): Promise<ProfileOnboarding> => {
	return await axios.post(`${BASE_URL}/profile/onboarding`, data)
}
export const update_profile_onboarding = async (
	data: ProfileOnboarding
): Promise<ProfileOnboarding> => {
	return await axios.put(`${BASE_URL}/profile/onboarding/${data.id}`, data)
}
export type UserModel = {
	id?: string
	firstname?: string
	lastname?: string
	other_name?: string
	email?: string
	phone?: string
	gender?: 'male' | 'female' | ''
	address?: string
	zip_code?: string
	city?: string
	state?: string
	country?: string
	billing_frequency?: 'hourly'
	billing_rate?: number
	job_title?: string
	note?: string
	status?: boolean
	onboard_status?: 'draft' | 'sent' | 'completed'
	role_id?: string
	role?: RoleModel
	service_ids?: string[]
	created?: boolean
	date_hired?: string
	permissions?: PermissionsModel[]
	file?: FileModel
	media?: any
}
export type ProfileOnboarding = OnboardingSettingsModel & {
	user_onboardings: {
		id?: string
		value?: string
		issue_date?: string
		expiry_date?: string
		file_id?: string
		onboarding_setting_id?: string
		onboarding_setting?: OnboardingSettingsModel
		files?: FileModel[]
		media?: any
	}[]
}
