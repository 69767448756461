import { Cloudinary } from '@cloudinary/url-gen'
import { FileModel } from '../service/misc'

const cld = new Cloudinary({
	cloud: {
		cloudName: 'tobilasinde',
	},
})
export const fileSrc = (file: FileModel, height?: number, width?: number) => {
	let transformation = 'c_fill,'
	let url = cld.image(file.id)
	if (height) transformation += `h_${height},`
	if (width) transformation += `w_${width},`
	url.addTransformation(transformation)
	return file.provider === 'cloudinary' ? url.toURL() : ''
}
