import React, { createContext, useContext, useEffect, useState } from 'react'
import WebLayout from './webLayout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

export const UserProfile = createContext({ userProfile: null, pamission: null })

export default function MainApp() {
	const params = useParams()
	const { pathname } = useLocation()
	const { currentUser } = useAuth()
	const navigate = useNavigate()
	useEffect(() => {
		const exclude = ['/', '/dashboard', '/profile']
		if (
			process.env.REACT_APP_ADMIN?.includes(currentUser.role?.name) ||
			exclude.includes(pathname)
		)
			return
		else if (currentUser?.permissions) {
			let path = pathname.split('/').reverse()
			path = path.filter(
				(item) => Object.values(params).includes(item) === false
			)
			let path_to_use = path[0] || path[1]
			let check = 'read'
			if (['create', 'edit', 'preview'].includes(path_to_use)) {
				check =
					path_to_use === 'create'
						? 'create'
						: path_to_use === 'edit'
						? 'update'
						: 'read'
				const index = path.indexOf(path_to_use)
				path_to_use = path[index + 1]
			}
			const permission = currentUser.permissions.find(
				(item: any) => item.model_name === path_to_use
			)
			if (!permission || permission[check] !== true) {
				navigate('/')
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, currentUser])
	const [menuOpen] = useState(true)

	return (
		<UserProfile.Provider value={{ pamission: null, userProfile: null }}>
			<WebLayout />
			{/* <div className="flex h-screen ">


        <div className={`hidden sm:block sm:w-2/12  fixed sm:block overflow-hidden transition-all ease-in-out duration-400 
        ${menuOpen ? ' sm:max-w-96  overflow-hidden' : 'sm:max-w-16  overflow-hidden'} `}
          id="sidebar">
          <Sidebar />
        </div>
  


        <div className={`sm:block hidden w-full 
         ${menuOpen ?
            ' sm:pl-60 transition-all ease-in-out duration-800 '
            : 'sm:pl-16 transition-all ease-in-out duration-700 '} 
           `}>
          <div className='flex'>
            <button className="open-btn  m-1" onClick={() => setMenu(!menuOpen)}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <Navbar />
          </div>
          <div className='bg-[#F1F4FB] p-6 h-screen '>
            <Outlet />
          </div>
        </div>
      
        <div className='w-full block sm:hidden `'>
          <MobileLayout />
        </div>


        <footer className={`fixed bg-white text-center text-sm w-full bottom-0 p-4 ${menuOpen ? 'sm:block hidden ' : 'sm:block  block '}`}>

				<p>
					Copyright © {new Date().getFullYear()} Velocity Manager. All rights
					reserved.
				</p>



        </footer>
      </div> */}
			<footer
				className={`fixed bg-white text-center text-sm w-full bottom-0 p-4 ${
					menuOpen ? 'sm:block hidden ' : 'sm:block  block '
				}`}
			>
				<p>
					Copyright © {new Date().getFullYear()} Velocity Manager. All rights
					reserved
				</p>
			</footer>
		</UserProfile.Provider>
	)
}

export const useProfilePamitions = () => {
	return useContext(UserProfile)
}
